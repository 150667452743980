<template>
    <div class="produce-center">
<!--      顶部图片-->
      <img :src="bannerSrc" class="top-image">
<!--      中间内容-->
      <el-container style="margin: 60px 20%;min-height: 700px">
        <el-aside style="width: 220px">
          <el-menu
            router
            active-text-color="#286DFF"
            text-color="#666666"
            :default-active="defaultActive"
            class="el-menu-vertical-demo"
            @open="handleOpen"
            @close="handleClose">
            <el-submenu v-for="(item,index) in menuRouter" :key="item.code" :index="index.toString()">
              <template slot="title">
                <span class="sub-menu-icon"></span>
                <span class="sub-menu-title">{{item.title}}</span>
              </template>
              <el-menu-item
                class="menu-item-title"
                v-for="childItem in item.children"
                :key="childItem.code"
                :index="childItem.url"
              >{{childItem.title}}</el-menu-item>
            </el-submenu>
          </el-menu>
        </el-aside>
        <el-main>
          <router-view v-if="isRouterAlive"></router-view>
        </el-main>
      </el-container>
<!--      招募伙伴-->
      <Recruit />
<!--      侧边浮窗-->
      <SideFloat />
    </div>
</template>

<script>
import { Recruit, SideFloat } from '@components'
export default {
  name: 'Produce',
  components: {
    Recruit,
    SideFloat
  },
  provide () {
    return {
      reload: this.reload
    }
  },
  data () {
    return {
      isRouterAlive: true,
      bannerSrc: require('@images/produce/pic_banner_1.png'),
      menuRouter: [
        {
          title: '院前云急救调度系统',
          code: 1,
          children: [
            {
              url: '/produce/dispatcher',
              title: '急救云调度系统',
              code: 11
            },
            {
              url: '/produce/zlb',
              title: '浙里办浙江急救',
              code: 12
            },
            {
              url: '/produce/helper',
              title: '患者端',
              code: 13
            },
            {
              url: '/produce/subscription',
              title: '患者端微信公众号',
              code: 14
            },
            {
              url: '/produce/ambulance',
              title: '车载端',
              code: 15
            },
            {
              url: '/produce/doctor',
              title: '医疗端',
              code: 16
            },
            {
              url: '/produce/warning',
              title: '医院预警端',
              code: 17
            }
          ]
        },
        {
          code: 2,
          title: '急救质量监控系统',
          children: [
            {
              url: '/produce/admin',
              title: '管理端',
              code: 21
            },
            {
              url: '/produce/back',
              title: '急救中心后台管理系统',
              code: 22
            },
            {
              url: '/produce/province',
              title: '省级管理平台',
              code: 23
            },
            {
              url: '/produce/driver',
              title: '急救数字驾驶舱',
              code: 24
            }
          ]
        }
      ]
    }
  },
  created () {
  },
  computed: {
    defaultActive: function () {
      return this.$route.path
    }
  },
  methods: {
    handleOpen (key, keyPath) {
      console.log(key, keyPath)
    },
    handleClose (key, keyPath) {
      console.log(key, keyPath)
    },
    reload () {
      this.isRouterAlive = false
      this.$nextTick(function () {
        this.isRouterAlive = true
      })
    }
  }
}
</script>

<style lang="less" scoped>
  .produce-center {
    .top-image {
      height: 220px;
    }
    .el-menu {
      box-shadow: 0 2px 6px 0 rgba(63,99,232,0.15);
      border-radius: 8px;
      border-right: none;
      .el-submenu {
        border-bottom: 1px solid rgba(63,99,232,0.10);
        /deep/.el-submenu__title {
          padding: 0 15px 0 25px;
          .sub-menu-title {
            font-size: 16px;
            color: #38467B;
            font-weight: bold;
          }
          .sub-menu-icon {
            width: 14px;
            height: 20px;
            display: inline-block;
            position: absolute;
            top: 50%;
            left: 0;
            transform: translateY(-50%);
            background: url("../../images/produce/pic_cpzx_title_blue.svg") no-repeat;
          }
          .menu-item-title {
            font-size: 16px;
            color: #747EA3;
          }
          .el-submenu__icon-arrow {
            color:  #38467B;
          }
        }
        &.is-active {
          border-radius: 0 8px 0 0;
          /deep/.el-submenu__title {
            background-color: #3F63E8;
            .sub-menu-title {
              color: #ffffff;
            }
            .sub-menu-icon {
              background: url("../../images/produce/pic_cpzx_title_white.svg") no-repeat;
            }
            .el-submenu__icon-arrow {
              color: #ffffff;
            }
            :hover {
              background-color: #3F63E8;
            }
          }
        }
      }
    }
    .el-main {
      padding: 0 40px;
    }
  }

</style>
